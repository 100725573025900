import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from '@emotion/styled';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { Container } from '../components/styled-components';
import Bio from '../components/Bio';

const Title = styled.h1`
  margin: 30px 0 10px;
  color: #171717;
  font-family: Montserrat;
  font-size: 2.5rem;
  font-weight: 900;
  line-height: 1.1;
  letter-spacing: 0.5px;
`;

const Date = styled.p`
  margin-bottom: 30px;
  color: #757575;
  font-family: Merriweather;
`;

const Content = styled.section`
  font-family: Merriweather;
  letter-spacing: 0.5px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 45px 0 30px;
    color: #171717;
    font-weight: 900;
  }

  p {
    margin-bottom: 30px;
    font-size: 1rem;
    line-height: 1.75rem;
  }

  blockquote {
    padding-left: 30px;
    border-left: 3px solid currentColor;
    color: #757575;
    font-style: oblique;
    font-weight: 300;
  }

  ul {
    margin-bottom: 30px;
    padding-left: 30px;
  }

  li {
    margin-bottom: 10px;
    line-height: 1.75rem;
  }

  pre {
    margin-bottom: 30px;
  }
`;

const Hr = styled.hr`
  margin-bottom: 30px;
`;

const LinksList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 60px;
  list-style: none;
  padding: 0;
`;

const MyLink = styled(Link)`
  border-bottom: 1px solid currentColor;
  color: #007acc;

  &:hover {
    border-bottom: 0;
  }
`;

export default function BlogPostTemplate({ data, pageContext }) {
  const post = data.markdownRemark;
  const { previous, next } = pageContext;

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />

      <Container>
        <article>
          <header>
            <Title>{post.frontmatter.title}</Title>
            <Date>{post.frontmatter.date}</Date>
          </header>

          <Content dangerouslySetInnerHTML={{ __html: post.html }} />
          <Hr />

          <footer>
            <Bio />
          </footer>
        </article>

        <LinksList>
          <li>
            {previous && (
              <MyLink to={previous.frontmatter.path} rel="prev">
                ← {previous.frontmatter.title}
              </MyLink>
            )}
          </li>
          <li>
            {next && (
              <MyLink to={next.frontmatter.path} rel="next">
                {next.frontmatter.title} →
              </MyLink>
            )}
          </li>
        </LinksList>
      </Container>
    </Layout>
  );
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        path
      }
    }
  }
`;
